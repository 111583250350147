<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/laboratory/banner2.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">浪涌（冲击）抗扰度试验</div>
                </div>
                <div class="yongtu">用途：检测产品遭受浪涌冲击（模拟雷电带来的瞬时高压、高能量的脉冲冲击）时的性能，以评估产品遭受浪涌冲击时的抗扰度等级是否符合设计要求。</div>
                <div class="canshu">主要技术参数：电压波：1.2/50us，电压波形峰值：0 ~ 6Kv，击打方式：同步相位 0 ~ 360 连续可调,异步AYSTN</div>
                <div class="shebei">设备：雷击浪涌发生器</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">工频磁场抗扰度试验</div>
                </div>
                <div class="yongtu">用途：检测产品遭受工频磁场干扰时的性能，以评估产品遭受工频磁场干扰时的抗扰度等级是否符合设计要求。</div>
                <div class="canshu">主要技术参数：测试电流：1 ~ 1200A，测试时间：100A 以下：0.1 ~ 999.9s，100A 以上：0.1 ~ 5.0s，测试次数：1 ~ 99 次</div>
                <div class="shebei">设备：工频磁场发生器</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">静电放电抗扰度试验</div>
                </div>
                <div class="yongtu">用途：检测产品遭受静电放电（放电现象，可能是人接触产生的；也可能是不同物体接触产生的）时的性能，以评估产品遭受静电放电时的抗扰度等级是否符合设计要求。</div>
                <div class="canshu">主要技术参数：电压范围：0.1kV ~ 30kV，放电模式：单次、连续（最大间隔 9.99s），储能电容 150Pf,放电电阻330Ω</div>
                <div class="shebei">设备：静电放电发生器</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">电快速瞬变脉冲群抗扰度试验</div>
                </div>
                <div class="yongtu">用途：检测产品遭受电快速脉冲群干扰时的性能，以评估产品遭受电快速脉冲群干扰时的抗扰度等级是否符合设计要求。</div>
                <div class="canshu">主要技术参数：脉冲波形：5/50ns±30%，电压范围：0.2kV ~ 4.5kV，源阻抗：50Ω，I/O 测试，电容耦合方式：电容 0.5uF</div>
                <div class="shebei">设备：脉冲群发生器</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">阻尼振荡波抗扰度试验</div>
                </div>
                <div class="yongtu">用途：检测产品遭受衰减振荡波（衰减振荡波是脉冲群中一种类型，模拟系统中大功率感性设备启停产生的瞬态噪声）干扰时的性能，以评估产品遭受衰减振荡波干扰时的抗扰度等级是否符合设计要求。</div>
                <div class="canshu">主要技术参数：振荡频率：100kHz 和 1MHz，重复频率：100kHz 至少 40/s，1MHz 至少 400/s，电压上升时间：75ns±20%，输出阻抗：200Ω±20%</div>
                <div class="shebei">设备：衰减荡波发生器</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">射频场感应的传导骚扰抗扰度</div>
                </div>
                <div class="yongtu">用途：检测产品遭受射频场感应的骚扰（主要模拟 150kHz到80MHz无线电波对控制器的影响）时的性能，以评估产品遭受射频场感应的骚扰时的抗扰度等级是否符合设计要求。</div>
                <div class="canshu">主要技术参数：频率范围：100kHz ~ 400MHz，输出功率：75W，测试等级：1V、3V、10V</div>
                <div class="shebei">设备：一体化传导敏感度测试系统</div>
            </div>
            <div class="item">
                <div class="titles">
                    <div class="ticon"></div>
                    <div class="ticon2"></div>
                    <div class="wenzi">汽车干扰模拟试验（抛负载测试）</div>
                </div>
                <div class="yongtu">用途：模拟在（发电机组中）充电机给铅酸电池组充电过程中铅酸电池组突然断开所产生瞬态过电压对产品电源口的冲击。检测产品在遭受这种过电压时的性能，以评估产品的可靠性是否符合设计要求。</div>
                <div class="canshu">主要技术参数：12V 系统电压范围：40V ~ 105V，24V 系统电压范围：100V ~ 204V</div>
                <div class="shebei">设备：汽车干扰模拟发生器</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 50%;
        margin: 0 auto;
        margin-top: 20px;
        .item{
            margin-bottom: 30px;
            .titles{
                display: flex;
                position: relative;
                .ticon{
                    width: 10px;
                    height: 10px;
                    background: #0052D9;
                    border-radius: 50%;
                    z-index: 2;
                    margin-top: 10px;
                }
                .ticon2{
                    width: 10px;
                    height: 10px;
                    background: #2DC84D;
                    border-radius: 50%;
                    position: absolute;
                    left: 6px;
                    margin-top: 10px;
                }
                .wenzi{
                    font-size: 20px;
                    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                    font-weight: bold;
                    color: #333333;
                    line-height: 30px;
                    margin-left: 20px;
                }
            }
            .yongtu{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
            }
            .yongtu{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
                line-height: 30px;
            }
            .canshu{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
            }
            .shebei{
                font-size: 14px;
                font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                font-weight: 400;
                color: #333333;
                margin-top: 15px;
            }
        }
        
    }
</style>